import React, { useEffect, useState } from "react";
import "./AdminBlogDetail.scss";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../../Constants/constants";
import {
  Typography,
  Row,
  Col,
  Divider,
  Tag,
  Breadcrumb,
  Avatar,
  Spin,
  Card,
  Space,
  Button,
  Popconfirm,
  message,
} from "antd";
import {
  CalendarOutlined,
  UserOutlined,
  TagOutlined,
  ArrowLeftOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const { Title, Paragraph, Text } = Typography;

const AdminBlogDetails = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Check if user is logged in as admin
  const userObject = JSON.parse(localStorage.getItem("userObject"));
  const isAdmin = userObject?.user?.role === "admin";

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${BASE_URL}/api/blog/${id}`);
        setBlog(response.data);
      } catch (error) {
        console.error("Error fetching blog details:", error);
        message.error("Failed to load blog details");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchBlogDetails();
    }
  }, [id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleEdit = () => {
    navigate(`/admin/edit-blog/${id}`);
  };

  const handleDelete = async () => {
    try {
      const token = userObject?.token;

      await axios.delete(`${BASE_URL}/api/blog/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      message.success("Blog deleted successfully");
      navigate("/admin/blogs");
    } catch (error) {
      console.error("Error deleting blog:", error);
      message.error("Failed to delete blog");
    }
  };

  if (loading) {
    return (
      <div className="loading">
        <Spin size="large" />
      </div>
    );
  }

  if (!blog) {
    return (
      <div className="no-blog">
        <Title level={3}>Blog not found</Title>
        <Paragraph>
          The blog you're looking for doesn't exist or has been removed.
        </Paragraph>
      </div>
    );
  }

  return (
    <div
      className="blog-detail-container"
      style={{ marginTop: isAdmin ? "0" : "120px" }}
    >
      {/* Breadcrumb navigation */}
      <Breadcrumb className="breadcrumb">
        <Breadcrumb.Item href={"/admin/all-blogs"}>
          <div style={{ fontSize: "15px" }}>
            <ArrowLeftOutlined /> Return to Blogs
          </div>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Row gutter={[24, 24]} justify={isAdmin ? "start" : "center"}>
        <Col xs={24} lg={isAdmin ? 16 : 20}>
          {/* Main content */}
          <div>
            <div className="main-container">
              <img className="" src={blog.blogImage} alt="Blog Image" />
            </div>

            <div className="header">
              <Title level={1}>{blog.title}</Title>

              {isAdmin && (
                <Space>
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    onClick={handleEdit}
                  >
                    Edit
                  </Button>
                  <Popconfirm
                    title="Delete this blog?"
                    description="This action cannot be undone"
                    onConfirm={handleDelete}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button danger icon={<DeleteOutlined />}>
                      Delete
                    </Button>
                  </Popconfirm>
                </Space>
              )}
            </div>

            <Space size={16} style={{ marginBottom: "20px" }}>
              <Text type="secondary">
                <CalendarOutlined /> {formatDate(blog.createdAt)}
              </Text>
              <Text type="secondary">
                <UserOutlined /> {blog.author?.name || "Anonymous"}
              </Text>
            </Space>

            <div style={{ marginBottom: "24px" }}>
              {blog.tags &&
                blog.tags.map((tag, index) => (
                  <Tag color="blue" key={index}>
                    {tag}
                  </Tag>
                ))}
            </div>

            <Divider />

            {/* Blog content with dangerouslySetInnerHTML to render HTML content */}
            <div
              className="blog-content"
              dangerouslySetInnerHTML={{ __html: blog.content }}
              style={{ fontSize: "16px", lineHeight: "1.6" }}
            />
          </div>
        </Col>

        {isAdmin && (
          <Col xs={24} lg={8}>
            {/* Sidebar */}
            <div>
              <Card title="About the Author" style={{ marginBottom: "24px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar size={64} icon={<UserOutlined />} />
                  <div style={{ marginLeft: "16px" }}>
                    <Title level={5} style={{ margin: 0 }}>
                      {blog.author?.name || "Anonymous"}
                    </Title>
                    {blog.author?.email && (
                      <Text type="secondary">{blog.author.email}</Text>
                    )}
                  </div>
                </div>
              </Card>

              <Card title="Meta Information">
                <Paragraph>
                  <strong>Meta Title:</strong> {blog.metaTitle}
                </Paragraph>
                <Paragraph>
                  <strong>Meta Description:</strong> {blog.metaDescription}
                </Paragraph>
                <Paragraph>
                  <strong>Keywords:</strong> {blog.keywords}
                </Paragraph>
                <Divider />
                <Paragraph>
                  <TagOutlined /> Tags
                </Paragraph>
                <div>
                  {blog.tags &&
                    blog.tags.map((tag, index) => (
                      <Tag color="blue" key={index} style={{ margin: "4px" }}>
                        {tag}
                      </Tag>
                    ))}
                </div>
              </Card>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default AdminBlogDetails;
