import React, { useState } from "react";
import "./contact.scss";
import PrimaryButton from "../../Components/UI/PrimaryButton/PrimaryButton";
import axios from "axios";
import { BASE_URL } from "../../Constants/constants";
import { message } from "antd";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    subject: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);
      try {
        const result = await axios.post(`${BASE_URL}/send-email`, {
          senderEmail: formData.email,
          subject: formData.subject,
          message: formData.message,
          senderName: formData.name,
        });

        console.log("RESULT: ", result);

        if (result.status === 200) {
          message.success("Your message has been sent successfully!");

          setFormData({
            name: "",
            email: "",
            message: "",
            subject: "",
          });
        }
      } catch (error) {
        message.error("Failed to send your message. Please try again.");
        console.error("Error:", error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = "Name is required";
    }
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email address";
    }
    if (!formData.message) {
      errors.message = "Message is required";
    }
    if (!formData.subject) {
      errors.subject = "Subject is required";
    }
    return errors;
  };

  return (
    <>
      <div id="contactForm">
        <h1> Get in Touch </h1>
        <div className="input_fields">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Your name"
              name="name"
              value={formData?.name}
              onChange={handleChange}
            />
            {errors.name && <span className="error_name">{errors.name}</span>}
            <input
              type="email"
              placeholder="Email address"
              name="email"
              value={formData?.email}
              onChange={handleChange}
            />
            {errors.email && (
              <span className="error_email">{errors.email}</span>
            )}

            <input
              type="text"
              placeholder="Subject"
              name="subject"
              value={formData?.subject}
              onChange={handleChange}
            />
            {errors.subject && (
              <span className="error_email">{errors.subject}</span>
            )}

            <textarea
              placeholder="Let us know your thoughts to contact..."
              cols="7"
              rows="5"
              name="message"
              value={formData?.message}
              onChange={handleChange}
            ></textarea>
            {errors.message && (
              <span className="error_message">{errors.message}</span>
            )}
            <div className="submit">
              <PrimaryButton type="submit" disable={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Submit"}
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Contact;
