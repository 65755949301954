import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import AdminRoutes from "./Routes/AdminRoutes";
import UserRoutes from "./Routes/UserRoutes";

function App() {
  const [isNav, setIsNav] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="app-container">
      <div className={isNav ? "drawer" : ""}>
        {pathname.startsWith("/admin") ? <AdminRoutes /> : <UserRoutes />}
      </div>
    </div>
  );
}

export default App;
