import React, { useEffect, useState } from "react";
import "./AllBlogs.scss";
import axios from "axios";
import { BASE_URL } from "../../../../Constants/constants";
import {
  Card,
  Row,
  Col,
  Typography,
  Tag,
  Spin,
  Empty,
  Button,
  Popconfirm,
  message,
} from "antd";
import {
  CalendarOutlined,
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../Components/UI/PrimaryButton/PrimaryButton";

const { Title, Paragraph, Text } = Typography;

const AllBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getAllBlogs = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/api/blog`);
      setBlogs(response?.data?.blog);
    } catch (error) {
      console.log(error);
      message.error("Failed to fetch blogs");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // Function to strip HTML tags for preview
  const stripHtml = (html) => {
    const tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const handleEdit = (blogId) => {
    navigate(`/admin/edit-blog/${blogId}`);
  };

  const handleDelete = async (blogId) => {
    try {
      const userObject = JSON.parse(localStorage.getItem("userObject"));
      const token = userObject?.token;

      await axios.delete(`${BASE_URL}/api/blog/${blogId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      message.success("Blog deleted successfully");
      getAllBlogs();
    } catch (error) {
      console.error("Error deleting blog:", error);
      message.error("Failed to delete blog");
    }
  };

  return (
    <div className="all-blogs-container">
      <div className="all-blogs-header">
        <Title level={2}>All Blogs</Title>

        <PrimaryButton
          type="primary"
          onClick={() => navigate("/admin/create-blog")}
        >
          Create New Blog
        </PrimaryButton>
      </div>

      {loading ? (
        <div style={{ textAlign: "center", padding: "40px" }}>
          <Spin size="large" />
        </div>
      ) : blogs && blogs.length > 0 ? (
        <Row gutter={[24, 24]}>
          {blogs.map((blog) => (
            <Col xs={24} sm={12} lg={8} key={blog._id}>
              <Card
                hoverable
                cover={
                  <img
                    className="card-image"
                    src={blog.blogImage}
                    alt="blog image"
                  />
                }
                style={{ height: "100%", padding: "2px" }}
                actions={[
                  <Link to={`/admin/blog/${blog._id}`}>
                    <EyeOutlined key="view" />
                  </Link>,
                  <EditOutlined
                    key="edit"
                    onClick={() => handleEdit(blog._id)}
                  />,
                  <Popconfirm
                    title="Delete this blog?"
                    description="This action cannot be undone"
                    onConfirm={() => handleDelete(blog._id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteOutlined key="delete" />
                  </Popconfirm>,
                ]}
              >
                <Title level={4} ellipsis={{ rows: 2 }}>
                  {blog.title}
                </Title>

                <div className="card-tags">
                  {blog.tags &&
                    blog.tags.map((tag, index) => (
                      <Tag
                        color="blue"
                        key={index}
                        style={{ marginBlock: "5px" }}
                      >
                        {tag}
                      </Tag>
                    ))}
                </div>

                <Paragraph ellipsis={{ rows: 3 }}>
                  {stripHtml(blog?.description)}
                </Paragraph>

                <div className="card-footer">
                  <Text type="secondary">
                    <CalendarOutlined /> {formatDate(blog.createdAt)}
                  </Text>
                  <Text type="secondary">
                    <UserOutlined /> {blog.author?.name || "Author"}
                  </Text>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <Empty description="No blogs found" />
      )}
    </div>
  );
};

export default AllBlogs;
