import React, { useState } from "react";
import "./Login.scss";
import PrimaryButton from "../../../Components/UI/PrimaryButton/PrimaryButton";
import { message, Spin } from "antd";
import axios from "axios";
import { BASE_URL } from "../../../Constants/constants";
import { ReactSVG } from "react-svg";
import { footerLogo } from "../../../Assets";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    const newErrors = {};

    // Email validation
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }

    // Password validation
    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);

      try {
        const response = await axios.post(`${BASE_URL}/login`, {
          email: formData.email,
          password: formData.password,
        });

        if (response?.status === 200) {
          message.success("Login successful");
          const userObject = {
            token: response.data.token,
            user: response.data.user,
          };

          localStorage.setItem("userObject", JSON.stringify(userObject));

          navigate("/admin/dashboard");
        }
      } catch (error) {
        message.error(
          `${error.response.data.message}` || "Login failed. Please try again."
        );
        console.error("Login error:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="login-container">
      <div className="login-form-wrapper">
        <div className="login-logo">
          <ReactSVG src={footerLogo} />
        </div>

        <h1>Welcome Back</h1>
        <p className="login-subtitle">
          Enter your credentials to access your account
        </p>

        <form className="login-form" onSubmit={handleSubmit}>
          {errors.form && <div className="error-message">{errors.form}</div>}

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={errors.email ? "error" : ""}
              placeholder="Enter your email"
              disabled={isLoading}
            />
            {errors.email && <span className="error-text">{errors.email}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className={errors.password ? "error" : ""}
              placeholder="Enter your password"
              disabled={isLoading}
            />
            {errors.password && (
              <span className="error-text">{errors.password}</span>
            )}
          </div>

          <PrimaryButton
            type="submit"
            className="login-button"
            disable={isLoading}
          >
            {isLoading ? <Spin /> : "Login"}
          </PrimaryButton>
        </form>
      </div>
    </div>
  );
};

export default Login;
