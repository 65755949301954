import React, { useState } from "react";
import "./Topbar.scss";
import { ReactSVG } from "react-svg";
import {
  EditOutlined,
  LogoutOutlined,
  MenuOutlined,
  ProfileOutlined,
} from "@ant-design/icons";

import { Drawer } from "antd";
import { Link, NavLink, useLocation } from "react-router-dom";
import { footerLogo, person } from "../../../Assets";

const Topbar = ({ navigation }) => {
  const location = useLocation();
  //   const currentRoute = useCurrentRoute();
  const isIndex = location.pathname === "/admin" || location.pathname === "/";
  const [open, setOpen] = useState(false);
  const [showInput, setShowInput] = useState(true);
  const [inputPlaceholder, setInputPlaceholder] = useState("something");

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const logoutHandler = () => {
    console.log("logout");
  };

  const sidebarItems = [
    {
      name: "All Blogs",
      path: "/admin/all-blogs",
      icon: <ProfileOutlined />,
    },
    { name: "Create Blog", path: "/admin/create-blog", icon: <EditOutlined /> },
  ];

  const renderRoutes = sidebarItems.map((route) => {
    return (
      <NavLink
        key={route.name}
        to={route.path}
        className={route.name === "All Blogs" && isIndex ? "active" : ""}
      >
        <div className="menu-item">
          {route.icon}
          {route.name}
        </div>
      </NavLink>
    );
  });

  return (
    <div className="topbar">
      <div className="topbar-header">
        <div className="topbar-back-button">{/* <BackButton /> */}</div>

        <div>
          <h2 className="page-headings-in-topbar">
            {/* {currentRoute ? currentRoute.title : "Admin"} */}
          </h2>
          {/* <Breadcrumb /> */}
        </div>
      </div>

      <div className="main-topbar-wrapper">
        <div className="menu-and-picture-actions">
          <div className="mobile-menu-button">
            <MenuOutlined
              style={{ fontWeight: "700", fontSize: "25px" }}
              onClick={showDrawer}
            />
          </div>

          <div className="picture-actions">
            {/* <Link to={"/admin/notifications"}>
              <ReactSVG src={NotificationIcon} />
            </Link> */}

            <Link to={`/admin/profile`}>
              <ReactSVG src={person} />
            </Link>
          </div>
        </div>
      </div>
      <Drawer
        placement={"left"}
        closable={true}
        onClose={onClose}
        open={open}
        width={`${window.screen.width * 0.8}px`}
        key={"left"}
        className="mobile-menu-drawer"
      >
        <div className="sidebar sidebar-mobile">
          <img src={footerLogo} alt="Medidoc Logo" />
          <div onClick={onClose} className="sidebar-menu">
            {renderRoutes}
          </div>
          <Link
            to={`/admin/login`}
            className="logout-button"
            onClick={logoutHandler}
          >
            <LogoutOutlined />
            Logout
          </Link>
        </div>
      </Drawer>
    </div>
  );
};

export default Topbar;
