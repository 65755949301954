import React, { useEffect, useState } from "react";
import "./Blogs.scss";
import axios from "axios";
import { Card, Row, Col, Typography, Tag, Spin, Empty, message } from "antd";
import { CalendarOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../Constants/constants";

const { Title, Paragraph, Text } = Typography;

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getAllBlogs = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/api/blog`);
      setBlogs(response?.data?.blog);
    } catch (error) {
      console.log(error);
      message.error("Failed to fetch blogs");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // Function to strip HTML tags for preview
  const stripHtml = (html) => {
    const tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  return (
    <div className="blogs-container">
      {loading ? (
        <div className="loading">
          <Spin size="large" />
        </div>
      ) : blogs && blogs.length > 0 ? (
        <Row gutter={[24, 24]}>
          {blogs.map((blog) => (
            <Col xs={24} sm={12} lg={8} key={blog._id}>
              <Card
                hoverable
                cover={
                  <img
                    className="card-image"
                    src={blog.blogImage}
                    alt="blog image"
                  />
                }
                style={{ height: "100%" }}
                onClick={() => navigate(`/blog/${blog._id}`)}
              >
                <Title level={4} ellipsis={{ rows: 2 }}>
                  {blog.title}
                </Title>

                <div className="card-tags">
                  {blog.tags &&
                    blog.tags.map((tag, index) => (
                      <Tag
                        color="blue"
                        key={index}
                        style={{ marginBlock: "5px" }}
                      >
                        {tag}
                      </Tag>
                    ))}
                </div>

                <Paragraph ellipsis={{ rows: 3 }}>
                  {stripHtml(blog?.description)}
                </Paragraph>

                <div className="card-footer">
                  <Text type="secondary">
                    <CalendarOutlined /> {formatDate(blog.createdAt)}
                  </Text>
                  <Text type="secondary">
                    <UserOutlined /> {blog.author?.name || "Author"}
                  </Text>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <Empty description="No blogs found" />
      )}
    </div>
  );
};

export default Blogs;
