import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.scss";
import { Popconfirm } from "antd";
import {
  EditOutlined,
  AppstoreOutlined,
  ProfileOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { footerLogo } from "../../../Assets";

const sidebarItems = [
  // { name: "Dashboard", path: "/admin/dashboard", icon: <AppstoreOutlined /> },
  { name: "All Blogs", path: "/admin/all-blogs", icon: <ProfileOutlined /> },
  { name: "Create Blog", path: "/admin/create-blog", icon: <EditOutlined /> },
];

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isIndex = location.pathname === "/admin" || location.pathname === "/";

  const renderRoutes = sidebarItems.map((route) => {
    return (
      <NavLink
        key={route.name}
        to={route.path}
        className={route.name === "All Blogs" && isIndex ? "active" : ""}
      >
        <div className="menu-item">
          {route.icon}
          {route.name}
        </div>
      </NavLink>
    );
  });

  const handleLogout = () => {
    localStorage.removeItem("userObject");
    navigate("/admin/login");
  };

  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <img src={footerLogo} alt="logo" />
      </div>

      <div className="sidebar-menu">{renderRoutes}</div>
      <Popconfirm
        title="Logout!"
        description="Are you sure you want to logout?"
        icon={
          <QuestionCircleOutlined
            style={{
              color: "red",
            }}
          />
        }
        onConfirm={handleLogout}
        okText="Logout!"
        // okButtonProps={{ loading: isLoading }}
      >
        <button className="logout-button">
          <LogoutOutlined style={{ fontSize: "25px" }} />
          Logout
        </button>
      </Popconfirm>
    </div>
  );
};

export default Sidebar;
