import React, { useState } from "react";
import { Form, Input, Button, Card, Typography, message, Spin } from "antd";
import { LockOutlined } from "@ant-design/icons";
import axios from "axios";
import "./Profile.scss";
import { BASE_URL } from "../../../Constants/constants";
import PrimaryButton from "../../../Components/UI/PrimaryButton/PrimaryButton";

const { Title, Text } = Typography;

const Profile = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const userObject = JSON.parse(localStorage.getItem("userObject"));
  const token = userObject?.token;
  const userId = userObject?.user?._id;

  const onFinish = async (values) => {
    if (values.newPassword !== values.confirmPassword) {
      message.error("New passwords don't match!");
      return;
    }

    setLoading(true);
    try {
      await axios.put(
        `${BASE_URL}/api/admin/change-password/${userId}`,
        {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      message.success("Password updated successfully");
      form.resetFields();
    } catch (error) {
      console.error("Error updating password:", error);

      if (error.response?.status === 401) {
        message.error("Current password is incorrect");
      } else {
        message.error("Failed to update password");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="edit-password-container">
      <Card className="password-card">
        <Title level={2}>Change Password</Title>
        <Text type="secondary" className="subtitle">
          Please enter your current password and a new password to update your
          credentials
        </Text>

        <Form
          form={form}
          name="change_password"
          layout="vertical"
          onFinish={onFinish}
          className="password-form"
        >
          <Form.Item
            name="currentPassword"
            label="Current Password"
            rules={[
              {
                required: true,
                message: "Please enter your current password",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Enter current password"
              size="large"
            />
          </Form.Item>

          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                required: true,
                message: "Please enter your new password",
              },
              {
                min: 8,
                message: "Password must be at least 8 characters",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Enter new password"
              size="large"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirm New Password"
            rules={[
              {
                required: true,
                message: "Please confirm your new password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Confirm new password"
              size="large"
            />
          </Form.Item>

          <Form.Item className="button-container">
            <PrimaryButton disable={loading} type="submit">
              {loading ? <Spin /> : "Update Password"}
            </PrimaryButton>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Profile;
