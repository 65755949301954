import React, { useState, useEffect } from "react";
import "./Header.scss";
import { ReactSVG } from "react-svg";
import { headerLogo } from "../../Assets";
import PrimaryButton from "../UI/PrimaryButton/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";

function Header({ isNav, setIsNav }) {
  const navigate = useNavigate();
  const [isTopNavVisible, setIsTopNavVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTopNavVisible(true); // Make the top-nav-bar visible after 3 seconds
    }, 3000);

    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);
  const handleLoginClick = () => {
    window.open("https://www.halaxy.com/a/login", "_blank");
  };

  return (
    <>
      {/* Simple Top Navigation Bar */}
      <div className={`top-nav-bar ${isTopNavVisible ? "visible" : ""}`}>
        <div className="small-div"></div>
        <p className="centered-text">Doctors Are Online Now</p>
      </div>

      {/* Main Header */}
      <header
        className={`header-container ${isTopNavVisible ? "adjusted" : ""}`}
      >
        <div className="logo-menu">
          <div onClick={() => navigate("/")}>
            <ReactSVG src={headerLogo} className="logo" />
          </div>
          <div
            className={isNav ? "change" : "menu"}
            onClick={() => setIsNav(!isNav)}
          >
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </div>

        <div id="open" className={isNav ? "open" : "list-button"}>
          <ul>
            <Link
              // offset={-70}
              onClick={() => navigate("/booking")}
              // smooth
              // duration={500}
            >
              <li> Online Consult</li>
            </Link>
            <Link
              // offset={-80}
              onClick={() => navigate("/booking")}
              // smooth
              // duration={500}
            >
              <li> Request Prescription</li>
            </Link>
            <Link onClick={() => navigate("/booking")}>
              <li>Medical Certificate</li>
            </Link>
          </ul>
          <PrimaryButton
            className="button"
            onClick={() => navigate("/booking")}
          >
            Speak to a Doctor Now
          </PrimaryButton>
          {/* 
          <PrimaryButton className="button" onClick={handleLoginClick}>
            Log in / Sign up
          </PrimaryButton> */}
        </div>
      </header>
    </>
  );
}

export default Header;
