import React, { useState, useEffect } from "react";
import "./BlogEditor.scss";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { BASE_URL } from "../../../../Constants/constants";
import {
  message,
  Typography,
  Form,
  Input,
  Divider,
  Space,
  Spin,
  Button,
} from "antd";
import ImageUploader from "../../../Components/Common/ImageUploader/ImageUploader";
import { formats, modules } from "../../../Config/ReactQuillConfig";
import PrimaryButton from "../../../../Components/UI/PrimaryButton/PrimaryButton";
import { useNavigate, useParams } from "react-router-dom";

const { Title } = Typography;

const BlogEditor = () => {
  const { id } = useParams();
  const isEdit = !!id;
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const userObject = JSON.parse(localStorage.getItem("userObject"));
  const token = userObject?.token;
  const user = userObject?.user;

  const [imageFile, setImageFile] = useState(null);
  const [existingImageUrl, setExistingImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    description: "",
    metaTitle: "",
    metaDescription: "",
    keywords: "",
    tags: "",
  });

  // Fetch blog data if in edit mode
  useEffect(() => {
    if (isEdit) {
      fetchBlogData();
    }
  }, [isEdit, id]);

  useEffect(() => {
    if (!id) {
      form.resetFields();
      setImageFile(null);
      setExistingImageUrl("");
      setFormData({
        title: "",
        content: "",
        description: "",
        metaTitle: "",
        metaDescription: "",
        keywords: "",
        tags: "",
      });

      // Force form values update
      setTimeout(() => {
        form.setFieldsValue({
          title: "",
          content: "",
          description: "",
          metaTitle: "",
          metaDescription: "",
          keywords: "",
          tags: "",
          image: undefined,
        });
      }, 0);
    }
  }, [id, form]);

  const fetchBlogData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${BASE_URL}/api/blog/${id}`);
      const blogData = response.data;

      // Format tags back to comma-separated string
      const tagsString = blogData.tags ? blogData.tags.join(", ") : "";

      // Set existing image URL
      setExistingImageUrl(blogData.blogImage);

      // Update form data
      const initialData = {
        title: blogData.title || "",
        content: blogData.content || "",
        description: blogData.description || "",
        metaTitle: blogData.metaTitle || "",
        metaDescription: blogData.metaDescription || "",
        keywords: blogData.keywords || "",
        tags: tagsString,
      };

      setFormData(initialData);

      // Set form fields
      form.setFieldsValue(initialData);
    } catch (error) {
      console.error("Error fetching blog data:", error);
      message.error("Failed to fetch blog data");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (file) => {
    setImageFile(file);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    // Convert comma-separated tags to an array
    const tagsArray = formData.tags
      .split(",")
      .map((tag) => tag.trim())
      .filter((tag) => tag);

    const formValues = new FormData();

    formValues.append("title", formData.title);
    formValues.append("content", formData.content);
    formValues.append("description", formData.description);
    formValues.append("metaTitle", formData.metaTitle);
    formValues.append("metaDescription", formData.metaDescription);
    formValues.append("keywords", formData.keywords);
    formValues.append("author", user?._id);

    // Add each tag to the FormData
    tagsArray.forEach((tag, index) => {
      formValues.append(`tags[${index}]`, tag);
    });

    // Only require image for new blogs
    if (!isEdit && !imageFile) {
      message.error("Upload a Blog Image");
      setIsLoading(false);
      return;
    }

    // Only append image if there's a new one selected
    if (imageFile) {
      formValues.append("blogImage", imageFile);
    }

    try {
      let response;

      if (isEdit) {
        // Update existing blog
        console.log("Edit form data: ", formValues);
        response = await axios.put(`${BASE_URL}/api/blog/${id}`, formValues, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        message.success("Blog post updated successfully!");
      } else {
        // Create new blog
        response = await axios.post(`${BASE_URL}/api/blog`, formValues, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        message.success("Blog post created successfully!");
      }

      if (response.data) {
        form.resetFields();
        setImageFile(null);
        setExistingImageUrl("");
        setFormData({
          title: "",
          content: "",
          description: "",
          metaTitle: "",
          metaDescription: "",
          keywords: "",
          tags: "",
        });

        navigate("/admin/all-blogs");
      }
    } catch (error) {
      console.error("Error saving blog:", error);
      message.error(
        isEdit ? "Failed to update blog post." : "Failed to save blog post."
      );
    } finally {
      setIsLoading(false);
    }
  };

  // If data is loading in edit mode, show loading state
  if (isEdit && isLoading && Object.values(formData).every((value) => !value)) {
    return (
      <div className="loading">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Title level={2} className="title">
        {isEdit ? "Edit Blog Post" : "Create New Blog Post"}
      </Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={formData}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please enter a title" }]}
        >
          <Input
            name="title"
            placeholder="Enter blog title"
            onChange={handleInputChange}
            size="large"
          />
        </Form.Item>

        <Form.Item
          label="Short Description"
          name="description"
          rules={[
            { required: true, message: "Please enter a short description" },
          ]}
        >
          <Input.TextArea
            name="description"
            placeholder="Enter a short description for your blog"
            onChange={handleInputChange}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>

        <Form.Item
          label="Blog Image"
          name="image"
          rules={[
            {
              required: !existingImageUrl,
              message: "Please upload a blog image"
            }
          ]}
        >
          {existingImageUrl && (
            <div style={{ marginBottom: "10px" }}>
              <img
                src={existingImageUrl}
                alt="Current blog image"
                style={{ maxWidth: "200px", maxHeight: "150px" }}
              />
              <p>Current image (upload a new one to replace)</p>
            </div>
          )}
          <ImageUploader
            onImageChange={(file) => {
              handleImageChange(file);
              form.setFieldsValue({ image: file }); // Update form value
            }}
            resetImage={imageFile === null}
          />
        </Form.Item>

        <Form.Item
          label="Content"
          name="content"
          rules={[{ required: true, message: "Please enter blog content" }]}
        >
          <ReactQuill
            value={formData.content}
            onChange={(value) => setFormData({ ...formData, content: value })}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </Form.Item>

        <Form.Item label="Tags (comma separated)" name="tags">
          <Input
            name="tags"
            placeholder="e.g. technology, programming, react"
            onChange={handleInputChange}
          />
        </Form.Item>

        <Divider orientation="left">SEO Information</Divider>

        {/* Meta Title */}
        <Form.Item label="Meta Title" name="metaTitle">
          <Input
            name="metaTitle"
            placeholder="Meta title for SEO"
            onChange={handleInputChange}
          />
        </Form.Item>

        {/* Meta Description */}
        <Form.Item label="Meta Description" name="metaDescription">
          <Input.TextArea
            name="metaDescription"
            placeholder="Meta description for SEO"
            onChange={handleInputChange}
            autoSize={{ minRows: 2, maxRows: 4 }}
          />
        </Form.Item>

        {/* Keywords */}
        <Form.Item label="Keywords" name="keywords">
          <Input
            name="keywords"
            placeholder="SEO keywords (comma separated)"
            onChange={handleInputChange}
          />
        </Form.Item>

        <Form.Item>
          <Space>
            <PrimaryButton type="submit" disable={isLoading}>
              {isLoading ? (
                <Spin />
              ) : isEdit ? (
                "Update Blog Post"
              ) : (
                "Save Blog Post"
              )}
            </PrimaryButton>

            <Button onClick={() => navigate("/admin/all-blogs")} size="large">
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default BlogEditor;
