// ImageUploader.jsx
import React, { useState, useEffect } from "react";
import { Upload, Image, Button, message } from "antd";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import "./ImageUploader.scss";

const ImageUploader = ({ onImageChange, resetImage = false }) => {
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  // Reset the component if the parent signals a reset
  useEffect(() => {
    if (resetImage) {
      setFileList([]);
    }
  }, [resetImage]);

  const handleChange = ({ fileList: newFileList, file }) => {
    // Keep only the latest file
    const latestFile =
      newFileList.length > 0 ? newFileList[newFileList.length - 1] : null;
    setFileList(latestFile ? [latestFile] : []);

    if (file.status === "done" || file.status === "uploading") {
      onImageChange(file.originFileObj);
    } else if (file.status === "removed") {
      onImageChange(null);
    }
  };

  // Custom request to handle the file without uploading it immediately
  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("You can only upload image files!");
    }

    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("Image must be smaller than 5MB!");
    }

    return isImage && isLt5M;
  };

  const handleRemove = () => {
    onImageChange(null);
    setFileList([]);
    return true;
  };

  // Handle preview click
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  // Convert file to base64 for preview
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadButton = (
    <div>
      <UploadOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onRemove={handleRemove}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        customRequest={customRequest}
        maxCount={1}
        onPreview={handlePreview}
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>

      {/* Image Preview Modal */}
      {previewVisible && (
        <div
          className="image-preview-modal"
          onClick={() => setPreviewVisible(false)}
        >
          <Image preview={false} src={previewImage} alt="Preview" />
          <Button type="default" onClick={() => setPreviewVisible(false)}>
            <CloseOutlined />
          </Button>
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
