import React from "react";
import Layout from "../Admin/Components/Layout/Layout";
import { Route, Routes, Navigate } from "react-router-dom";
import BlogEditor from "../Admin/Pages/Blog/BlogEditor/BlogEditor";
import Login from "../Admin/Pages/Login/Login";
import Dashboard from "../Admin/Pages/Dashboard/Dashboard";
import AllBlogs from "../Admin/Pages/Blog/AllBlogs/AllBlogs";
import AdminBlogDetail from "../Admin/Pages/Blog/AdminBlogDetail/AdminBlogDetail";
import Profile from "../Admin/Pages/Profile/Profile";

const AdminRoutes = () => {
  const userObject = JSON.parse(localStorage.getItem("userObject"));
  const token = userObject?.token;
  const user = userObject?.user;

  if (!token || !user) {
    return (
      <Routes>
        <Route path="/admin/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/admin/login" />} />
      </Routes>
    );
  }

  return (
    <Layout>
      <Routes>
        {/* <Route path="/admin/dashboard" element={<Dashboard />} /> */}
        <Route path="/admin/create-blog" element={<BlogEditor />} />
        <Route path="/admin/all-blogs" element={<AllBlogs />} />
        <Route
          path="/admin/edit-blog/:id"
          element={<BlogEditor isEdit={true} />}
        />
        <Route path="/admin/blog/:id" element={<AdminBlogDetail />} />
        <Route path="/admin/profile" element={<Profile />} />

        <Route path="*" element={<Navigate to="/admin/all-blogs" />} />
      </Routes>
    </Layout>
  );
};

export default AdminRoutes;
