import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Landing from "../Pages/Landing/Landing";
import BookingIframe from "../Pages/iFrames/BookingIframe";
import DoctorProfile from "../Pages/iFrames/DoctorProfile";
import Diseases from "../Components/Diseases/Diseases";
import TermsAndConditions from "../Pages/termsAndConditions/TermsAndConditions";
import Privacypolicy from "../Pages/Privacypolicy/Privacypolicy";
import Blogs from "../Pages/Blogs/Blogs";
import BlogDetail from "../Pages/Blogs/BlogDetail";

const UserRoutes = () => {
  const [isNav, setIsNav] = useState(false);

  // function to get exact position from top during the navigation process.
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Header isNav={isNav} setIsNav={setIsNav} />

      <Routes>
        <Route
          path="/*"
          element={<Landing isNav={isNav} setIsNav={setIsNav} />}
        />
        <Route path="/booking" element={<BookingIframe />} />
        <Route path="/profile" element={<DoctorProfile />} />
        <Route path="/diseases" element={<Diseases />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<Privacypolicy />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog/:id" element={<BlogDetail />} />
      </Routes>

      <Footer setIsNav={setIsNav} isNav={isNav} />
    </div>
  );
};

export default UserRoutes;
