import React from "react";
import "./about.scss";
import { ReactSVG } from "react-svg";
import {
  Choose1,
  Choose2,
  Choose3,
  slideimg,
  // slideimg01,
  slideimg02,
  slideimg03,
  slideimg04,
  // slideimg05,
} from "../../Assets";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const About = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <>
      {/* header  */}
      <div id="aboutContainer">
        <h1 className="container-heading">Why Choose Us?</h1>
        <div className="about-header">
          <div className="about-header-left"></div>
          {/* <div className="about-header-right">
            <p>
              Our dedicated professionals are your beacon of hope during these
              critical times. They are trained, experienced, and ready to
              respond swiftly to provide the care and support you need. Whether
              it's a sudden injury, a medical crisis, or any other emergency,
              our Urgent Health Guardians are there to ensure you receive
              immediate attention and assistance.
            </p>
          </div> */}
        </div>
      </div>

      {/* slider  */}

      <div className="slides" style={{ display: "none" }}>
        <div className="slider">
          <Slider {...settings}>
            <div className="img">
              <img src={slideimg} alt="" />
            </div>
            <div className="img">
              <img src={slideimg02} alt="" />
            </div>
            <div className="img">
              <img src={slideimg03} alt="" />
            </div>
            <div className="img">
              <img src={slideimg04} alt="" />
            </div>
            <div className="img">
              <img src={slideimg} alt="" />
            </div>
            <div className="img">
              <img src={slideimg02} alt="" />
            </div>
            <div className="img">
              <img src={slideimg03} alt="" />
            </div>
            <div className="img">
              <img src={slideimg04} alt="" />
            </div>
            <div className="img">
              <img src={slideimg} alt="" />
            </div>
            <div className="img">
              <img src={slideimg02} alt="" />
            </div>
            <div className="img">
              <img src={slideimg03} alt="" />
            </div>
            <div className="img">
              <img src={slideimg04} alt="" />
            </div>
          </Slider>
        </div>
      </div>

      {/* Commitment and Healthier lives */}

      <div className="container">
        <div className="commitment_and_healthier_lives">
          <div className="commitment_and_healthier_lives_left">
            <img src={Choose1} alt="" />
            <h2>Accessible and Affordable Healthcare for All Australians</h2>
            <p>
              At Medidoc, our mission is simple: to deliver fast, quality, and
              affordable healthcare to everyone, no matter where they are in
              Australia. We believe that accessing medical advice should be
              easy, convenient, and stress-free.
            </p>
          </div>
          <div className="commitment_and_healthier_lives_right">
            <img src={Choose2} alt="" />
            <h2>Expert Care Through Secure Online Consultations</h2>
            <p>
              Our team of fully licensed and experienced Doctors are dedicated
              to providing the highest standard of care through secure,
              confidential online consultations. Whether you need a
              prescription, advice on a health concern, or a referral to a
              specialist, we’re here to help—quickly and efficiently.
            </p>
          </div>
          <div className="commitment_and_healthier_lives_right">
            <img src={Choose3} alt="" />
            <h2>Seamless Access to Trusted Healthcare Professionals</h2>
            <p>
              By using our technology, we ensure you can connect with trusted
              professionals without the hassle of travel or long wait times.
              Your health, your way—affordable, convenient, and reliable.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
