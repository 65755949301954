import React from "react";

import { Outlet } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";
import "./Layout.scss";

const Layout = ({ children }) => {
  return (
    <div className="layout-container">
      <Sidebar />
      <main>
        <Topbar />
        <div className="outlet-main-container">{children}</div>
      </main>
    </div>
  );
};

export default Layout;
