import React, { useEffect, useState } from "react";
import "./BlogDetail.scss";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Typography,
  Row,
  Col,
  Divider,
  Tag,
  Breadcrumb,
  Avatar,
  Spin,
  Card,
  Space,
  Button,
  Popconfirm,
  message,
} from "antd";
import {
  CalendarOutlined,
  UserOutlined,
  TagOutlined,
  ArrowLeftOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { BASE_URL } from "../../Constants/constants";

const { Title, Paragraph, Text } = Typography;

const BlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Check if user is logged in as admin
  const userObject = JSON.parse(localStorage.getItem("userObject"));
  const isAdmin = userObject?.user?.role === "admin";

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${BASE_URL}/api/blog/${id}`);
        setBlog(response.data);
      } catch (error) {
        console.error("Error fetching blog details:", error);
        message.error("Failed to load blog details");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchBlogDetails();
    }
  }, [id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  if (loading) {
    return (
      <div className="loading">
        <Spin size="large" />
      </div>
    );
  }

  if (!blog) {
    return (
      <div className="no-blog">
        <Title level={3}>Blog not found</Title>
        <Paragraph>
          The blog you're looking for doesn't exist or has been removed.
        </Paragraph>
      </div>
    );
  }

  return (
    <div className="blog-detail">
      {/* Breadcrumb navigation */}
      <Breadcrumb className="breadcrumb">
        <Breadcrumb.Item href={"/blogs"}>
          <div style={{ fontSize: "15px" }}>
            <ArrowLeftOutlined /> Return to Blogs
          </div>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Row gutter={[24, 24]} justify={"center"}>
        <Col xs={24} lg={20}>
          {/* Main content */}
          <div>
            <div className="main-container">
              <img src={blog.blogImage} alt="Blog Image" />
            </div>

            <div className="header">
              <Title level={1}>{blog.title}</Title>
            </div>

            <Space size={16} style={{ marginBottom: "20px" }}>
              <Text type="secondary">
                <CalendarOutlined /> {formatDate(blog.createdAt)}
              </Text>
              <Text type="secondary">
                <UserOutlined /> {blog.author?.name || "Anonymous"}
              </Text>
            </Space>

            <div style={{ marginBottom: "24px" }}>
              {blog.tags &&
                blog.tags.map((tag, index) => (
                  <Tag color="blue" key={index}>
                    {tag}
                  </Tag>
                ))}
            </div>

            <Divider />

            {/* Blog content with dangerouslySetInnerHTML to render HTML content */}
            <div
              className="blog-content"
              dangerouslySetInnerHTML={{ __html: blog.content }}
              style={{ fontSize: "16px", lineHeight: "1.6" }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BlogDetail;
